import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { BehaviorSubject, delay, Observable, take } from 'rxjs';

import { HdmuiAvatarComponent, HdmuiComponentsModule } from '@heidelberg/hdmui-angular';
import { CCPerson } from '@heidelberg/control-center-frontend-integration/cc';

import { Required } from '@data-terminal/utils';
import { HeidelbergLogoComponent } from '@data-terminal/ui-presentational';

import { TransitionText } from '../../model/transition-text.model';
import { TransitionType } from '../../model/transition-type';
import { LegalLineComponent } from '../../../../../../src/app/components/legal-line/legal-line.component';

@Component({
    standalone: true,
    imports: [
        TranslateModule,
        MatIconModule,
        MatButtonModule,
        HdmuiComponentsModule,
        MatTooltipModule,
        HdmuiAvatarComponent,
        AsyncPipe,
    ],
    selector: 'data-terminal-transition',
    templateUrl: './transition.component.html',
    styleUrls: ['./transition.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransitionComponent {
    @Input()
    @Required()
    type!: TransitionType;

    @Input()
    @Required()
    transitionText!: TransitionText;

    @Input()
    totalTime = 15;

    @Input()
    user?: CCPerson;

    @Output()
    buttonClick = new EventEmitter<void>();

    private readonly _timerSubj$ = new BehaviorSubject<number>(this.totalTime);
    private readonly _timer$ = this._timerSubj$.asObservable();

    constructor() {
        this._timer$.pipe(take(this.totalTime), delay(1000)).subscribe((timeLeft) => {
            if (timeLeft > 1) {
                const newTime = timeLeft - 1;
                this._timerSubj$.next(newTime);
            } else {
                this.onButtonClick();
            }
        });
    }

    onButtonClick(): void {
        this.buttonClick.emit();
    }

    get timer$(): Observable<number> {
        return this._timer$;
    }
}

@Component({
    standalone: true,
    imports: [HeidelbergLogoComponent, LegalLineComponent],
    selector: 'data-terminal-transition-wrapper',
    template: `
        <div class="hdmui-m-4 transition__content">
            <div class="logo__container">
                <data-terminal-heidelberg-logo></data-terminal-heidelberg-logo>
            </div>
            <div class="transition__wrapper">
                <ng-content select="data-terminal-transition"></ng-content>
            </div>
            <app-legal-line></app-legal-line>
        </div>
    `,
    styles: [
        `
            @use '../../../../../ui-styles/dc-styles' as ui-styles;

            .transition__content {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                flex-direction: column;
            }

            .logo__container {
                display: flex;
                justify-content: flex-end;
            }

            .transition__wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                margin-bottom: ui-styles.$heidelbergLogoHeight;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransitionWrapperComponent {}
