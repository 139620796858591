import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, take, tap } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';

import { ROUTE_PATH } from '@data-terminal/shared-models';
import { CloudSessionService } from '@data-terminal/data-access';
import { isToday } from '@data-terminal/utils';

import { TransitionApiService } from '../transition-api/transition-api.service';
import { UserStatistics } from '../../model/user.statistics.model';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class TransitionService {
    private _userStatistics!: UserStatistics;

    constructor(
        private readonly router: Router,
        private readonly cloudSessionService: CloudSessionService,
        private readonly transitionApiService: TransitionApiService,
        private readonly ccAuthService: CCAuthService
    ) {}

    public get shouldDisplayWelcomeScreen$(): Observable<boolean> {
        const lastSessionUpdateDate = this.cloudSessionService.getSessionData()?.updatedAt;

        if (!lastSessionUpdateDate) {
            return of(true);
        }

        return of(!isToday(new Date(lastSessionUpdateDate)));
    }

    public fetchUserStatistics$(): Observable<UserStatistics> {
        return this.transitionApiService.getUserStats().pipe(
            tap((userStatistics) => {
                this._userStatistics = userStatistics;
            })
        );
    }

    public get userStatistics(): UserStatistics {
        return this._userStatistics;
    }

    public navigateToWelcomePage(): void {
        const orgId = this.ccAuthService.getCurrentMembership()?.organizationId;
        this.router.navigateByUrl(`${orgId}/${ROUTE_PATH.welcome}`);
    }

    // TODO: to be implemented in the sign-off machine process
    public navigateToFarewellPage(): void {
        const orgId = this.ccAuthService.getCurrentMembership()?.organizationId;
        this.fetchUserStatistics$()
            .pipe(
                take(1),
                tap(() => {
                    this.router.navigateByUrl(`${orgId}/${ROUTE_PATH.farewell}`);
                })
            )
            .subscribe();
    }
}
