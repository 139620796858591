import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { delay, Observable, of } from 'rxjs';

import { BACKEND_URL, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';

import { FeatureTransitionModule } from '../../feature-transition.module';
import { UserStatistics } from '../../model/user.statistics.model';

@Injectable({
    providedIn: FeatureTransitionModule,
})
export class TransitionApiService {
    // private readonly USER_STATS_URL = this.backendUrl + this.orgRequestPrefix + `user-stats`;

    constructor(
        private readonly http: HttpClient,
        private readonly translateService: TranslateService,
        @Inject(BACKEND_URL) private readonly backendUrl: string,
        @Inject(ORGANIZATION_REQUEST_PREFIX) private readonly orgRequestPrefix: string
    ) {}

    // TODO: will be discussed on the refinement
    public getUserStats(): Observable<UserStatistics> {
        return of({
            stat1: 'a',
            stat2: 'b',
            stat3: 'c',
            stat4: 'd',
        } as UserStatistics).pipe(delay(1000));
    }
}
